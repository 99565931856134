@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.edit-goals {
  margin-bottom: spacing.$xl;
  &__list {
    margin-top: spacing.$l;
    margin-bottom: spacing.$xl;
    padding: spacing.$m;
    border-radius: corner-radius.$s;
    background: light.$surface-primary-default;
  }

  &__buttons {
    > * + * {
      margin-left: spacing.$xs;
    }
  }
}
